<template>
  <v-container
    grid-list-xl
    fluid
    fill-height>
    <v-menu
      open-on-hover
      bottom
      right
      offset-y
      transition="slide-y-transition"
      style="position: absolute; right: 0; top: 0; padding: 15px;"
    >
      <router-link
        v-ripple
        slot="activator"
        class="toolbar-items"
        to=""
      >
        <v-badge
          color="error"
          overlap
        >
          <span style="text-transform: uppercase; color: rgba(0,0,0,.87);">{{ selectedLocale.locale }}</span>
        </v-badge>
      </router-link>
      <v-card>
        <v-list dense>
          <v-list-tile
            v-for="language in languages"
            :key="language.locale"
            @click="changeLanguage(language)"
          >
            <v-list-tile-title
              v-text="$t(language.name)"
            />
          </v-list-tile>
        </v-list>
      </v-card>
    </v-menu>
    <v-layout
      align-center
      justify-center>
      <v-flex
        xs12
        sm12
        md8>
        <v-card class="elevation-12">
          <v-toolbar
            :color="forConasi ? 'primary' : ''"
            flat
          >
            <v-toolbar-title style="text-align: center;">
              <img
                v-if="forConasi"
                src="../../assets/conasi_logo.png"
                width="200px">
              <img
                v-else
                src="../../assets/e_voucher_logo.svg"
                width="200px">
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <h3 class="card-title text-color mt-3 text-center text-uppercase">
              {{ $t("registerSupplier.title") }}
            </h3>
            <p class="text-center text-color">
              {{ $t("registerSupplier.subtitle") }}
            </p>
          </v-card-text>
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-layout>
                <v-flex
                  xs12
                  sm6
                  md6
                >
                  <v-layout wrap>
                    <v-flex
                      xs12
                      sm12
                      md12
                    >
                      <h4 class="card-title text-color mt-3 text-center text-uppercase">
                        {{ $t("registerSupplier.account") }}
                      </h4>
                    </v-flex>

                    <v-flex
                      xs12
                      sm12
                      md12
                    >
                      <v-checkbox
                        :label="$t('register.alreadyAccount')"
                        v-model="alreadyHasAccount"/>
                    </v-flex>
                    <v-flex
                      v-if="!alreadyHasAccount"
                      xs12
                      sm12
                      md12
                    >
                      <v-text-field
                        v-model="fullName"
                        :rules="[ ruleRequiredValue, ruleValidFullname ]"
                        :label="$t('user.fullname')"
                        :name="$t('user.fullname')"
                        prepend-icon="mdi-account-box-outline"
                        type="text"
                        class="required"
                      />
                    </v-flex>
                    <v-flex
                      xs12
                      sm12
                      md12
                    >
                      <v-text-field
                        :rules="[ ruleRequiredValue, ruleValidEmail ]"
                        v-model="email"
                        :label="$t('common.email')"
                        :name="$t('common.email')"
                        prepend-icon="mdi-email"
                        type="text"
                        class="required"
                      />
                    </v-flex>
                    <v-flex
                      xs12
                      sm12
                      md12
                    >
                      <v-text-field
                        id="password"
                        :rules="[ alreadyHasAccount ? ruleRequiredValue : ruleValidPassword ]"
                        v-model="password"
                        :label="$t('login.password')"
                        :name="$t('login.password')"
                        prepend-icon="mdi-lock"
                        type="password"
                        class="required"
                      />
                    </v-flex>
                    <v-flex
                      v-if="!alreadyHasAccount"
                      xs12
                      sm12
                      md12
                    >
                      <v-text-field
                        id="password"
                        v-model="confirmPassword"
                        :rules="[ ruleRequiredValue, ruleSamePassword ]"
                        :label="$t('register.confirmPassword')"
                        :name="$t('register.confirmPassword')"
                        prepend-icon="mdi-lock"
                        type="password"
                        class="required"
                      />
                    </v-flex>
                    <v-flex
                      v-if="!alreadyHasAccount"
                      xs12
                      sm12
                      md12
                    >
                      <v-text-field
                        :rules="[ ruleValidPhoneNumber ]"
                        v-model="phone"
                        :label="$t('user.phone')"
                        :name="$t('user.phone')"
                        prepend-icon="mdi-cellphone-iphone"
                        type="number"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  md6
                  style="background:#FCFCFC"
                >
                  <v-layout wrap>
                    <v-flex
                      xs12
                      sm12
                      md12
                    >
                      <h4 class="card-title text-color mt-3 text-center text-uppercase">
                        {{ $t("registerSupplier.supplierInfo") }}
                      </h4>
                    </v-flex>

                    <v-flex
                      xs12
                      sm12
                      md12
                    >
                      <v-text-field
                        :rules="[ ruleRequiredValue ]"
                        v-model="supplierName"
                        :label="$t('registerSupplier.name') "
                        :name="$t('registerSupplier.name')"
                        prepend-icon="mdi-tag-text-outline"
                        type="text"
                        class="required"
                      />
                    </v-flex>
                    <v-flex
                      xs12
                      sm12
                      md12
                    >
                      <v-text-field
                        :rules="[ ruleRequiredValue, ruleValidEmail ]"
                        v-model="supplierEmail"
                        :label="$t('registerSupplier.email')"
                        :name="$t('registerSupplier.email')"
                        prepend-icon="mdi-email-variant"
                        type="text"
                        class="required"
                      />
                    </v-flex>
                    <v-flex
                      xs12
                      sm12
                      md12
                    >
                      <v-text-field
                        :rules="[ ruleRequiredValue, ruleValidPhoneNumber ]"
                        v-model="supplierPhone"
                        :label="$t('registerSupplier.phone')"
                        :name="$t('registerSupplier.phone')"
                        prepend-icon="mdi-phone-classic"
                        type="number"
                        class="required"
                      />
                    </v-flex>
                    <v-flex
                      xs12
                      sm12
                      md12
                    >
                      <v-text-field
                        :rules="[ ruleRequiredValue, ruleValidUrlPath ]"
                        v-model="supplierWebsite"
                        :label="$t('registerSupplier.website')"
                        :name="$t('registerSupplier.website')"
                        prepend-icon="mdi-web"
                        type="text"
                        class="required"
                      />
                    </v-flex>
                    <v-flex
                      xs12
                      sm12
                      md12
                    >
                      <v-text-field
                        :rules="[ ruleRequiredValue ]"
                        v-model="supplierAddress.fullAddress"
                        :label="$t('registerSupplier.address')"
                        :name="$t('registerSupplier.address')"
                        prepend-icon="mdi-map-marker-outline"
                        append-outer-icon="mdi-dots-horizontal"
                        type="text"
                        class="required"
                        readonly
                        @click:append-outer="onEditAddress"
                      />
                    </v-flex>
                    <v-flex
                      xs12
                      sm12
                      md12
                    >
                      <v-textarea
                        v-model="supplierDescription"
                        :label="$t('registerSupplier.description')"
                        :name="$t('registerSupplier.description')"
                        prepend-icon="mdi-information-outline"
                        rows="1"
                        type="text"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              class="v-btn-plus"
              color="success"
              @click="onRegister($event)">{{
                $t("registerSupplier.register")
              }}</v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
        <div class="text-center font-italic mt-2">
          <a href="/login">{{ $t('registerSupplier.gotoLogin') }}</a>
        </div>
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar" />
    <loading-bar :is-loading="isLoading" />
    <update-address-modal
      ref="updateAddressModal"
      @onConfirm="onUpdateAddress"
    />
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { FOR_CONASI_TARGET } from 'utils/constants'
// import stringUtils from 'utils/stringUtils'
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
import ToastType from 'enum/toastType'
import UpdateAddressModal from 'Components/UpdateAddressModal'
export default {
  components: {
    UpdateAddressModal
  },
  data: vm => ({
    valid: true,
    alreadyHasAccount: false,
    email: null,
    password: null,
    confirmPassword: null,
    fullName: null,
    phone: null,
    supplierName: null,
    supplierPhone: null,
    supplierEmail: null,
    supplierWebsite: null,
    supplierDescription: null,
    supplierAddress: {
      longitude: null,
      latitude: null,
      province: null,
      district: null,
      ward: null,
      street: null,
      fullAddress: null
    },
    isLoading: false,
    forConasi: FOR_CONASI_TARGET
  }),
  computed: {
    ...mapGetters([
      'languages',
      'selectedLocale',
      'GET_SNACK_BAR'
    ])
  },
  methods: {
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    ruleValidEmail (value) {
      return validationRules.ruleValidEmailFormat(value)
    },
    ruleValidPhoneNumber (value) {
      return validationRules.ruleValidPhoneNumber(value)
    },
    ruleValidFullname (value) {
      return validationRules.ruleValidFullname(value)
    },
    ruleValidUrlPath (value) {
      return validationRules.ruleValidUrlPath(value)
    },
    ruleValidPassword (value) {
      return validationRules.ruleValidPassword(value)
    },
    ruleSamePassword (value) {
      return validationRules.ruleSamePassword(value, this.password)
    },
    /**
     * Change language
     */
    changeLanguage (language) {
      this.$i18n.locale = language.locale
      this.$store.dispatch('changeLanguage', language)
    },
    getFullAddress: function () {
      this.supplierAddress.fullAddress = this.supplierAddress.street
      let addressFilter = {
        params: {
          countryCode: 84,
          cityCode: this.supplierAddress.province,
          districtCode: this.supplierAddress.district,
          wardCode: this.supplierAddress.ward
        }
      }
      this.GET_ADDRESS_DETAIL(addressFilter).then(
        function (res) {
          this.supplierAddress.fullAddress = functionUtils.concatFullAddress(res.data, this.supplierAddress.street)
        }.bind(this)
      )
    },
    onEditAddress: function () {
      this.$refs.updateAddressModal.onShowModal(this.supplierAddress)
    },
    onUpdateAddress: function (address) {
      this.supplierAddress.province = address.province
      this.supplierAddress.district = address.district
      this.supplierAddress.ward = address.ward
      this.supplierAddress.longitude = address.longitude
      this.supplierAddress.latitude = address.latitude
      this.supplierAddress.street = address.street
      this.getFullAddress()
    },
    validate () {
      if (this.$refs.form.validate()) {
        this.snackbar = true
        let email = functionUtils.removeSpaceInString(this.email.toLowerCase())
        let supplierEmail = functionUtils.removeSpaceInString(this.supplierEmail.toLowerCase())

        let filter = {
          alreadyHasAccount: this.alreadyHasAccount,
          accountEmail: email,
          accountPassword: this.password,
          accountFullName: this.fullName,
          accountPhone: this.phone,
          supplierName: this.supplierName,
          supplierPhone: this.supplierPhone,
          supplierEmail: supplierEmail,
          supplierWebsite: this.supplierWebsite,
          supplierDescription: this.supplierDescription,
          supplierLongitude: this.supplierAddress.longitude,
          supplierLatitude: this.supplierAddress.latitude,
          supplierProvince: this.supplierAddress.province,
          supplierDistrict: this.supplierAddress.district,
          supplierWard: this.supplierAddress.ward,
          supplierStreet: this.supplierAddress.street
        }
        this.isLoading = true
        this.REGISTER_SUPPLIER_NEW_CUSTOMER(filter).then(
          function () {
            this.isLoading = false
            this.ON_SHOW_TOAST({
              'message': this.$t('login.pleaseActivateAccount'),
              'styleType': ToastType.SUCCESS
            })
            this.$router.push({
              name: 'login'
            })
          }.bind(this)
        ).catch(
          function (error) {
            this.isLoading = false
            if (!functionUtils.isNull(error.response.data.errorText)) {
              let errorText = error.response.data.errorText
              this.ON_SHOW_TOAST({
                'message': this.$t(errorText),
                'styleType': ToastType.ERROR
              })
            } else {
              this.ON_SHOW_TOAST({
                'message': this.$t('login.somethingWentWrong'),
                'styleType': ToastType.ERROR
              })
            }
          }.bind(this)
        )
      }
    },
    /**
     * On signup
     */
    onRegister: function () {
      this.validate()
    },
    ...mapActions([
      'REGISTER_SUPPLIER_NEW_CUSTOMER',
      'GET_ADDRESS_DETAIL',
      'ON_SHOW_TOAST'
    ])
  }
}
</script>
<style lang="scss" scoped>
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
